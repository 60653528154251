const initialState = {
    startYear: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SET_YEAR':
            return {
                ...state,
                startYear: action.payload
            };
        default:
            return state;
    }
}
