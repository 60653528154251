import { combineReducers } from "redux";
import language from "./language";
import chatmessages from "./chatmsg";
import chatStatus from "./chatToggle";
import notifications from "./notification";
import viewed from "./viewed";
import lessons from "./lessons";
import teachers from "./teachers";
import students from "./students";
import classShedule from './classShedule'
import lessonContent from './lessonContent'
import schoolClass from './schoolClass'
import user from "./user"
import modes from './modes'
import subjects from './subjects'
import results from './results'
import annuals from './annuals'
import lang from './lang'
import subjectNames from './subjectNames'
import admins from './admins'
import users from './users'
import images from './images'
import parents from './parents'
import confirmation from './confirmation'
import lessonsDones from './lessonsDones'
import assessments from './assessments'
import startYear from './startYear'

export default combineReducers({
  language: language,
  chatmessages,
  chatStatus,
  notifications,
  viewed,
  lessons,
  teachers,
  students,
  classShedule,
  lessonContent,
  schoolClass,
  user,
  modes,
  subjects,
  results,
  annuals,
  lang,
  subjectNames,
  admins,
  users,
  images,
  parents,
  confirmation,
  lessonsDones,
  assessments,
  startYear,
});
